var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "actionlist", tag: "section" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              _c(
                "base-material-card",
                {
                  attrs: { color: "success", icon: "mdi-badge-account" },
                  scopedSlots: _vm._u([
                    {
                      key: "after-heading",
                      fn: function() {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", "max-width": "50%" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "card-title font-weight-light"
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                Менеджер задач\n                "
                                          ),
                                          _c(
                                            "v-icon",
                                            { staticClass: "help-pointer" },
                                            [
                                              _vm._v(
                                                "\n                  mdi-help-circle-outline\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "На данном экране отображаются просроченные,"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              сегодняшние и задачи на завтра,"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              а также задачи без срока выполнения."
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              Выполняйте актуальные задачи и отмечайте их выполненными."
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              Если задачу невозможно выполнить в указанный срок,"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              измените дату ее исполнения на актуальный срок.\n            "
                                )
                              ])
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0 pl-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    color: "secondary",
                                    rounded: "",
                                    target: "_blank"
                                  },
                                  on: { click: _vm.createAction }
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(
                                      "\n                  mdi-plus\n                "
                                    )
                                  ]),
                                  _vm._v(
                                    "\n                Добавить\n              "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-col", { staticClass: "pt-0 pb-0" }),
                          _c("v-col", {
                            staticClass: "pt-0 pb-0",
                            attrs: { cols: "12", md: "auto" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mb-4",
                              attrs: { outlined: "", large: "", color: "error" }
                            },
                            [
                              _c(
                                "h2",
                                [
                                  _vm._v("\n                Просроченные "),
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { color: "error" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.expiredItems.length) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("v-data-table", {
                            attrs: {
                              loading: _vm.loading,
                              "loading-text": "Загрузка...",
                              headers: _vm.headers,
                              items: _vm.expiredItems,
                              search: _vm.search,
                              "sort-by": ["date", "time", "id"],
                              "sort-desc": [false],
                              "hide-default-footer": "",
                              "disable-pagination": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "body",
                                fn: function(ref) {
                                  var items = ref.items
                                  return [
                                    _c(
                                      "tbody",
                                      _vm._l(items, function(item) {
                                        return _c(
                                          "tr",
                                          {
                                            key: item.id,
                                            class: { archived: !item.status },
                                            on: {
                                              click: function($event) {
                                                return _vm.singleAction(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("td", [_vm._v(_vm._s(item.id))]),
                                            _c("td", [
                                              item.client
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/client/" +
                                                          item.client.id,
                                                        title:
                                                          "Открыть клиента в новой вкладке",
                                                        target: "_blank"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "break-chip",
                                                          attrs: {
                                                            color:
                                                              "pink lighten-5",
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          #" +
                                                              _vm._s(
                                                                item.client.id
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.client
                                                                  .parents
                                                                  ? item.client
                                                                      .parents
                                                                  : item.client
                                                                      .name
                                                                  ? item.client
                                                                      .name
                                                                  : ""
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.teacher
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/teacher/" +
                                                          item.teacher.id,
                                                        title:
                                                          "Открыть преподавателя в новой вкладке",
                                                        target: "_blank"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "break-chip",
                                                          attrs: {
                                                            color:
                                                              "purple lighten-5",
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          #" +
                                                              _vm._s(
                                                                item.teacher.id
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.teacher
                                                                  .name
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [_vm._v(_vm._s(item.action))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text--disabled"
                                                },
                                                [_vm._v(_vm._s(item.comment))]
                                              )
                                            ]),
                                            _c("td", {
                                              attrs: { align: "center" },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.formatDate(
                                                    item.date,
                                                    item.time
                                                  )
                                                )
                                              }
                                            })
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                outlined: "",
                                large: "",
                                color: "primary"
                              }
                            },
                            [
                              _c(
                                "h2",
                                [
                                  _vm._v("\n                Сегодня "),
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { color: "primary" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.todayItems.length) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("v-data-table", {
                            attrs: {
                              loading: _vm.loading,
                              "loading-text": "Загрузка...",
                              headers: _vm.headers,
                              items: _vm.todayItems,
                              search: _vm.search,
                              "sort-by": ["date", "time", "id"],
                              "sort-desc": [false],
                              "hide-default-footer": "",
                              "disable-pagination": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "body",
                                fn: function(ref) {
                                  var items = ref.items
                                  return [
                                    _c(
                                      "tbody",
                                      _vm._l(items, function(item) {
                                        return _c(
                                          "tr",
                                          {
                                            key: item.id,
                                            class: { archived: !item.status },
                                            on: {
                                              click: function($event) {
                                                return _vm.singleAction(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("td", [_vm._v(_vm._s(item.id))]),
                                            _c("td", [
                                              item.client
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/client/" +
                                                          item.client.id,
                                                        title:
                                                          "Открыть клиента в новой вкладке",
                                                        target: "_blank"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "break-chip",
                                                          attrs: {
                                                            color:
                                                              "pink lighten-5",
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          #" +
                                                              _vm._s(
                                                                item.client.id
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.client
                                                                  .parents
                                                                  ? item.client
                                                                      .parents
                                                                  : item.client
                                                                      .name
                                                                  ? item.client
                                                                      .name
                                                                  : ""
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.teacher
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/teacher/" +
                                                          item.teacher.id,
                                                        title:
                                                          "Открыть преподавателя в новой вкладке",
                                                        target: "_blank"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "break-chip",
                                                          attrs: {
                                                            color:
                                                              "purple lighten-5",
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          #" +
                                                              _vm._s(
                                                                item.teacher.id
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.teacher
                                                                  .name
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [_vm._v(_vm._s(item.action))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text--disabled"
                                                },
                                                [_vm._v(_vm._s(item.comment))]
                                              )
                                            ]),
                                            _c("td", {
                                              attrs: { align: "center" },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.formatDate(
                                                    item.date,
                                                    item.time
                                                  )
                                                )
                                              }
                                            })
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                outlined: "",
                                large: "",
                                color: "success"
                              }
                            },
                            [
                              _c(
                                "h2",
                                [
                                  _vm._v("\n                Завтра "),
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { color: "success" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.tomorrowItems.length) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("v-data-table", {
                            attrs: {
                              loading: _vm.loading,
                              "loading-text": "Загрузка...",
                              headers: _vm.headers,
                              items: _vm.tomorrowItems,
                              search: _vm.search,
                              "sort-by": ["date", "time", "id"],
                              "sort-desc": [false],
                              "hide-default-footer": "",
                              "disable-pagination": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "body",
                                fn: function(ref) {
                                  var items = ref.items
                                  return [
                                    _c(
                                      "tbody",
                                      _vm._l(items, function(item) {
                                        return _c(
                                          "tr",
                                          {
                                            key: item.id,
                                            class: { archived: !item.status },
                                            on: {
                                              click: function($event) {
                                                return _vm.singleAction(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("td", [_vm._v(_vm._s(item.id))]),
                                            _c("td", [
                                              item.client
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/client/" +
                                                          item.client.id,
                                                        title:
                                                          "Открыть клиента в новой вкладке",
                                                        target: "_blank"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "break-chip",
                                                          attrs: {
                                                            color:
                                                              "pink lighten-5",
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          #" +
                                                              _vm._s(
                                                                item.client.id
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.client
                                                                  .parents
                                                                  ? item.client
                                                                      .parents
                                                                  : item.client
                                                                      .name
                                                                  ? item.client
                                                                      .name
                                                                  : ""
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.teacher
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/teacher/" +
                                                          item.teacher.id,
                                                        title:
                                                          "Открыть преподавателя в новой вкладке",
                                                        target: "_blank"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "break-chip",
                                                          attrs: {
                                                            color:
                                                              "purple lighten-5",
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          #" +
                                                              _vm._s(
                                                                item.teacher.id
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.teacher
                                                                  .name
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [_vm._v(_vm._s(item.action))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text--disabled"
                                                },
                                                [_vm._v(_vm._s(item.comment))]
                                              )
                                            ]),
                                            _c("td", {
                                              attrs: { align: "center" },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.formatDate(
                                                    item.date,
                                                    item.time
                                                  )
                                                )
                                              }
                                            })
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                outlined: "",
                                large: "",
                                color: "secondary"
                              }
                            },
                            [
                              _c(
                                "h2",
                                [
                                  _vm._v("\n                Без срока "),
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { color: "secondary" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.otherItems.length) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("v-data-table", {
                            attrs: {
                              loading: _vm.loading,
                              "loading-text": "Загрузка...",
                              headers: _vm.headersNoDate,
                              items: _vm.otherItems,
                              search: _vm.search,
                              "sort-by": ["id"],
                              "sort-desc": [true],
                              "hide-default-footer": "",
                              "disable-pagination": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "body",
                                fn: function(ref) {
                                  var items = ref.items
                                  return [
                                    _c(
                                      "tbody",
                                      _vm._l(items, function(item) {
                                        return _c(
                                          "tr",
                                          {
                                            key: item.id,
                                            class: { archived: !item.status },
                                            on: {
                                              click: function($event) {
                                                return _vm.singleAction(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("td", [_vm._v(_vm._s(item.id))]),
                                            _c("td", [
                                              item.client
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/client/" +
                                                          item.client.id,
                                                        title:
                                                          "Открыть клиента в новой вкладке",
                                                        target: "_blank"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "break-chip",
                                                          attrs: {
                                                            color:
                                                              "pink lighten-5",
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          #" +
                                                              _vm._s(
                                                                item.client.id
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.client
                                                                  .parents
                                                                  ? item.client
                                                                      .parents
                                                                  : item.client
                                                                      .name
                                                                  ? item.client
                                                                      .name
                                                                  : ""
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.teacher
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "/teacher/" +
                                                          item.teacher.id,
                                                        title:
                                                          "Открыть преподавателя в новой вкладке",
                                                        target: "_blank"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "break-chip",
                                                          attrs: {
                                                            color:
                                                              "purple lighten-5",
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          #" +
                                                              _vm._s(
                                                                item.teacher.id
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.teacher
                                                                  .name
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [_vm._v(_vm._s(item.action))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text--disabled"
                                                },
                                                [_vm._v(_vm._s(item.comment))]
                                              )
                                            ]),
                                            _c("td", {
                                              attrs: { align: "center" },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.formatDate(
                                                    item.date,
                                                    item.time
                                                  )
                                                )
                                              }
                                            })
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmArchiveDialog,
            callback: function($$v) {
              _vm.confirmArchiveDialog = $$v
            },
            expression: "confirmArchiveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Переместить задачу в архив?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        После помещения в архив задача не будет отображаться несмотря на статус исполнения.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmArchiveDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.archive(_vm.selectedAction)
                        }
                      }
                    },
                    [_vm._v("\n          Переместить в архив\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmUnArchiveDialog,
            callback: function($$v) {
              _vm.confirmUnArchiveDialog = $$v
            },
            expression: "confirmUnArchiveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Вернуть задачу из архива?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        После возврата задачи из архива он будет отображаться в списке задач, пока не будет выполнена.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmUnArchiveDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.unarchive(_vm.selectedAction)
                        }
                      }
                    },
                    [_vm._v("\n          Вернуть из архива\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }