<template>
  <v-container
    id="actionlist"
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          color="success"
          icon="mdi-badge-account"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Менеджер задач
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>На данном экране отображаются просроченные,<br>
                сегодняшние и задачи на завтра,<br>
                а также задачи без срока выполнения.<br>
                Выполняйте актуальные задачи и отмечайте их выполненными.<br>
                Если задачу невозможно выполнить в указанный срок,<br>
                измените дату ее исполнения на актуальный срок.
              </span>
            </v-tooltip>
          </template>

          <v-card-text
            class="pt-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0 pl-0"
              >
                <v-btn
                  class="ma-1"
                  color="secondary"
                  rounded
                  target="_blank"
                  @click="createAction"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </v-col>
              <v-col
                class="pt-0 pb-0"
              />

              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              />
            </v-row>
          </v-card-text>

          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <v-chip
                class="mb-4"
                outlined
                large
                color="error"
              >
                <h2>
                  Просроченные <v-chip
                    color="error"
                    class="ml-3"
                  >
                    {{ expiredItems.length }}
                  </v-chip>
                </h2>
              </v-chip>
              <v-data-table
                :loading="loading"
                loading-text="Загрузка..."
                :headers="headers"
                :items="expiredItems"
                :search="search"
                :sort-by="['date', 'time', 'id']"
                :sort-desc="[false]"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      :class="{ archived: !item.status }"
                      @click="singleAction(item)"
                    >
                      <td>{{ item.id }}</td>
                      <td>
                        <a
                          v-if="item.client"
                          :href="`/client/${item.client.id}`"
                          title="Открыть клиента в новой вкладке"
                          target="_blank"
                          @click.stop=""
                        >
                          <v-chip
                            class="break-chip"
                            color="pink lighten-5"
                            x-small
                          >
                            #{{ item.client.id }} {{ item.client.parents ? item.client.parents : (item.client.name ? item.client.name : '') }}
                            <v-icon
                              right
                              x-small
                            >mdi-open-in-new</v-icon>
                          </v-chip>
                        </a>
                        <a
                          v-if="item.teacher"
                          :href="`/teacher/${item.teacher.id}`"
                          title="Открыть преподавателя в новой вкладке"
                          target="_blank"
                          @click.stop=""
                        >
                          <v-chip
                            class="break-chip"
                            color="purple lighten-5"
                            x-small
                          >
                            #{{ item.teacher.id }} {{ item.teacher.name }}
                            <v-icon
                              right
                              x-small
                            >mdi-open-in-new</v-icon>
                          </v-chip>
                        </a>
                        <span class="d-block">{{ item.action }}</span>
                        <span class="d-block text--disabled">{{ item.comment }}</span>
                      </td>
                      <td
                        align="center"
                        v-html="formatDate(item.date, item.time)"
                      />
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-chip
                class="mb-4"
                outlined
                large
                color="primary"
              >
                <h2>
                  Сегодня <v-chip
                    color="primary"
                    class="ml-3"
                  >
                    {{ todayItems.length }}
                  </v-chip>
                </h2>
              </v-chip>
              <v-data-table
                :loading="loading"
                loading-text="Загрузка..."
                :headers="headers"
                :items="todayItems"
                :search="search"
                :sort-by="['date', 'time', 'id']"
                :sort-desc="[false]"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      :class="{ archived: !item.status }"
                      @click="singleAction(item)"
                    >
                      <td>{{ item.id }}</td>
                      <td>
                        <a
                          v-if="item.client"
                          :href="`/client/${item.client.id}`"
                          title="Открыть клиента в новой вкладке"
                          target="_blank"
                          @click.stop=""
                        >
                          <v-chip
                            class="break-chip"
                            color="pink lighten-5"
                            x-small
                          >
                            #{{ item.client.id }} {{ item.client.parents ? item.client.parents : (item.client.name ? item.client.name : '') }}
                            <v-icon
                              right
                              x-small
                            >mdi-open-in-new</v-icon>
                          </v-chip>
                        </a>
                        <a
                          v-if="item.teacher"
                          :href="`/teacher/${item.teacher.id}`"
                          title="Открыть преподавателя в новой вкладке"
                          target="_blank"
                          @click.stop=""
                        >
                          <v-chip
                            class="break-chip"
                            color="purple lighten-5"
                            x-small
                          >
                            #{{ item.teacher.id }} {{ item.teacher.name }}
                            <v-icon
                              right
                              x-small
                            >mdi-open-in-new</v-icon>
                          </v-chip>
                        </a>
                        <span class="d-block">{{ item.action }}</span>
                        <span class="d-block text--disabled">{{ item.comment }}</span>
                      </td>
                      <td
                        align="center"
                        v-html="formatDate(item.date, item.time)"
                      />
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-chip
                class="mb-4"
                outlined
                large
                color="success"
              >
                <h2>
                  Завтра <v-chip
                    color="success"
                    class="ml-3"
                  >
                    {{ tomorrowItems.length }}
                  </v-chip>
                </h2>
              </v-chip>
              <v-data-table
                :loading="loading"
                loading-text="Загрузка..."
                :headers="headers"
                :items="tomorrowItems"
                :search="search"
                :sort-by="['date', 'time', 'id']"
                :sort-desc="[false]"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      :class="{ archived: !item.status }"
                      @click="singleAction(item)"
                    >
                      <td>{{ item.id }}</td>
                      <td>
                        <a
                          v-if="item.client"
                          :href="`/client/${item.client.id}`"
                          title="Открыть клиента в новой вкладке"
                          target="_blank"
                          @click.stop=""
                        >
                          <v-chip
                            class="break-chip"
                            color="pink lighten-5"
                            x-small
                          >
                            #{{ item.client.id }} {{ item.client.parents ? item.client.parents : (item.client.name ? item.client.name : '') }}
                            <v-icon
                              right
                              x-small
                            >mdi-open-in-new</v-icon>
                          </v-chip>
                        </a>
                        <a
                          v-if="item.teacher"
                          :href="`/teacher/${item.teacher.id}`"
                          title="Открыть преподавателя в новой вкладке"
                          target="_blank"
                          @click.stop=""
                        >
                          <v-chip
                            class="break-chip"
                            color="purple lighten-5"
                            x-small
                          >
                            #{{ item.teacher.id }} {{ item.teacher.name }}
                            <v-icon
                              right
                              x-small
                            >mdi-open-in-new</v-icon>
                          </v-chip>
                        </a>
                        <span class="d-block">{{ item.action }}</span>
                        <span class="d-block text--disabled">{{ item.comment }}</span>
                      </td>
                      <td
                        align="center"
                        v-html="formatDate(item.date, item.time)"
                      />
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-chip
                class="mb-4"
                outlined
                large
                color="secondary"
              >
                <h2>
                  Без срока <v-chip
                    color="secondary"
                    class="ml-3"
                  >
                    {{ otherItems.length }}
                  </v-chip>
                </h2>
              </v-chip>
              <v-data-table
                :loading="loading"
                loading-text="Загрузка..."
                :headers="headersNoDate"
                :items="otherItems"
                :search="search"
                :sort-by="['id']"
                :sort-desc="[true]"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      :class="{ archived: !item.status }"
                      @click="singleAction(item)"
                    >
                      <td>{{ item.id }}</td>
                      <td>
                        <a
                          v-if="item.client"
                          :href="`/client/${item.client.id}`"
                          title="Открыть клиента в новой вкладке"
                          target="_blank"
                          @click.stop=""
                        >
                          <v-chip
                            class="break-chip"
                            color="pink lighten-5"
                            x-small
                          >
                            #{{ item.client.id }} {{ item.client.parents ? item.client.parents : (item.client.name ? item.client.name : '') }}
                            <v-icon
                              right
                              x-small
                            >mdi-open-in-new</v-icon>
                          </v-chip>
                        </a>
                        <a
                          v-if="item.teacher"
                          :href="`/teacher/${item.teacher.id}`"
                          title="Открыть преподавателя в новой вкладке"
                          target="_blank"
                          @click.stop=""
                        >
                          <v-chip
                            class="break-chip"
                            color="purple lighten-5"
                            x-small
                          >
                            #{{ item.teacher.id }} {{ item.teacher.name }}
                            <v-icon
                              right
                              x-small
                            >mdi-open-in-new</v-icon>
                          </v-chip>
                        </a>
                        <span class="d-block">{{ item.action }}</span>
                        <span class="d-block text--disabled">{{ item.comment }}</span>
                      </td>
                      <td
                        align="center"
                        v-html="formatDate(item.date, item.time)"
                      />
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmArchiveDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Переместить задачу в архив?
        </v-card-title>

        <v-card-text>
          После помещения в архив задача не будет отображаться несмотря на статус исполнения.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmArchiveDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="archive(selectedAction)"
          >
            Переместить в архив
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmUnArchiveDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Вернуть задачу из архива?
        </v-card-title>

        <v-card-text>
          После возврата задачи из архива он будет отображаться в списке задач, пока не будет выполнена.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmUnArchiveDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="unarchive(selectedAction)"
          >
            Вернуть из архива
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
  .v-data-table tr {
    cursor: pointer;
  }

  .help-pointer {
    cursor: pointer;
  }
</style>

<script>
  import actionApi, { ActionState } from '../services/ActionApi'
  import CRMStorage from '../services/CRMStorage'
  import moment from 'moment'

  export default {
    data () {
      return {
        search: CRMStorage.getItem('ML_search', ''),
        page: 1,
        itemsPerPage: CRMStorage.getItem('ML_itemsPerPage', 10),
        onlyArchived: CRMStorage.getItem('ML_onlyArchived', false),
        headers: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Задача',
            value: 'status',
          },
          {
            sortable: true,
            text: 'Срок',
            value: 'date',
          },
        ],
        headersNoDate: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Задача',
            value: 'action',
          },
          {
            sortable: false,
            text: '',
            value: 'date',
          },
        ],
        items: [],
        expiredItems: [],
        todayItems: [],
        tomorrowItems: [],
        otherItems: [],
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },

        confirmArchiveDialog: false,
        confirmUnArchiveDialog: false,
        selectedAction: null,
      }
    },

    computed: {
      onlyArchivedLabel () {
        if (this.items.length < 1) {
          return 'Показать архивные'
        }
        return 'Показать архивные (' + this.items.filter((item) => item.status === 0).length + ')'
      },
    },
    watch: {
      search: function (val) {
        this.page = 1
        CRMStorage.setItem('ML_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('ML_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('ML_page', val)
      },
      onlyArchived: function (val) {
        this.page = 1
        CRMStorage.setItem('ML_onlyArchived', val)
      },
      items: {
        handler () {
          this.page = +CRMStorage.getItem('ML_page', 1)
          setTimeout(function () {
            window.scrollTo(0, +CRMStorage.getItem('ML_scrollY', 0))
          }, 100)
        },
        deep: true,
      },
    },

    mounted () {
      document.title = 'Задачи | ' + process.env.VUE_APP_NAME
      if (!this.itemsPerPage) this.itemsPerPage = 10
      this.fetchData()
    },
    methods: {
      singleAction (item) {
        CRMStorage.setItem('ML_scrollY', window.scrollY)
        this.$router.push('/action/' + item.id)
      },
      createAction () {
        this.$router.push('/action/create')
        // return process.env.VUE_APP_OLD_URL + 'action/create'
      },
      handleError (errorResponse) {
        let data = errorResponse.response.data
        if (errorResponse.response.status === 422) {
          this.fieldsErrors = data
        } else if (errorResponse.response.status === 401) {
          this.error = 'Неверный логин или пароль! Войдите в систему заново!'
        } else if (data.message) {
          this.error = data.message
        } else {
          this.error = JSON.stringify(data, null, 2)
        }
        this.loading = false
      },
      fetchData () {
        this.loading = true
        actionApi
          .fetchActiveActions()
          .then(response => {
            this.items = response
            this.expiredItems = []
            this.todayItems = []
            this.tomorrowItems = []
            this.otherItems = []
            this.items.forEach((item) => {
              let state = actionApi.getActionState(item)
              if (ActionState.NO_DATE === state) {
                this.otherItems.push(item)
              } else if (ActionState.EXPIRED === state) {
                this.expiredItems.push(item)
              } else if (ActionState.TODAY === state) {
                this.todayItems.push(item)
              } else if (ActionState.TOMORROW === state) {
                this.tomorrowItems.push(item)
              }
            })
            this.loading = false
          })
          .catch(error => {
            this.handleError(error)
          })
      },

      imageLink (id) {
        return process.env.VUE_APP_OLD_URL + 'm/100/100/' + id
      },

      confirmArchive (action) {
        this.selectedAction = action
        this.confirmArchiveDialog = true
      },

      confirmUnArchive (action) {
        this.selectedAction = action
        this.confirmUnArchiveDialog = true
      },

      archive (action) {
        this.confirmArchiveDialog = false
        action.status = 0
        this.update(action)
      },

      unarchive (action) {
        this.confirmUnArchiveDialog = false
        action.status = 1
        this.update(action)
      },

      update (action) {
        if (action.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          actionApi
            .updateAction(action)
            .then(response => {
              actionApi.fetchActiveActions()
                .then(response => {
                  this.items = response
                  this.loading = false
                })
                .catch(error => {
                  this.handleError(error)
                })
            })
            .catch(error => {
              this.handleError(error)
            })
        }
      },
      formatDate (date, time) {
        if (!date) return null
        if (date && time) return moment(date + ' ' + time).format('DD.MM.YY<br>HH:mm')
        return moment(date).format('DD.MM.YY')
      },
    },
  }
</script>
<style>
  .v-data-table__wrapper table tr td {
    cursor: pointer;
  }

  .archived {
    opacity: 0.5;
    text-decoration: line-through;
  }

  .help-pointer {
    cursor: pointer;
  }
</style>
